.contents-rounded {
  border-radius: 15px !important;
}

.content-img-rounded {
  border-radius: 15px 15px 0 0;
}

.contents:hover {
  transform: scale(1.029);
}