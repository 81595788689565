.hero-image {
  background-color: rgba(0, 0, 0, 0.266);
  background-blend-mode: darken;
  background-size: cover;
  height: 80vh !important;
  /* position: relative;
  object-fit: contain; */
  /* min-height: 80vh; */
}

.owner-vertical {
  background-image: url("./banner-img/owner-vertical.jpg");
}

.owner-horizontal {
  background-image: url("./banner-img/owner-horizontal.jpg");
}

.servicer-vertical {
  background-image: url("./banner-img/servicer-vertical.jpg");
}

.servicer-horizontal {
  background-image: url("./banner-img/servicer-horizontal.jpg");
}

.realtor-vertical {
  background-image: url("./banner-img/realtor-vertical.jpg");
}

.realtor-horizontal {
  background-image: url("./banner-img/realtor-horizontal.jpg");
}